.bg-img{
    background-image: url("./about.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    padding :5% 0;
}

.about-text{
    background-color: rgb(255, 255, 255, 0.5);
    padding: 2%;
}
