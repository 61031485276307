@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap);
.specialfont {
  font-family: "Open Sans", sans-serif;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.myselect {
  padding: 12px;
  border: 2px solid black;
  text-transform: uppercase;
  width: 100%;
}

.responsive {
  width: 70%;
  height: auto;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: red;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-transform: uppercase;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.heading-mobile{
  font-size :24px;margin-top:-28px;
}
.heading-desktop {
  font-size:24px;
  margin-top: 15px;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

div.ex3 {
  height: 300px;
  overflow: auto;
}

.priceslider {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}

.alphabetsdesigners {
  padding: 10px;
  font-size: 20px;
  color: #000000;
}

.brandname {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.productimg {
  width: 400px;
}

.goright {
  float: right;
}

.excelbutton {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px;
  float: right;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .footer-section {
    display: flex !important;
    justify-content: space-between;
  }
}

footer .tt-mobile-collapse .tt-collapse-title.tt-open + .tt-collapse-content {
  display: flex !important;
  justify-content: space-between;
}

.imgmobileheight {
  /* height: 237px !important */
}

.imgdesktopheight {
  height: 430px !important;
}
footer .tt-list li a {
    font-size: 14px;
}
/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the IdealBankElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=idealBank#elements_create-options-classes
*/

input,
.StripeElement {
  height: 40px;

  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

input {
  padding: 10px 12px;
}

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.bg-img{
    background-image: url(/static/media/about.1287c1e3.jpg);
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    padding :5% 0;
}

.about-text{
    background-color: rgb(255, 255, 255, 0.5);
    padding: 2%;
}

